<template>
  <SubPageWrapper empty-cols>
    <SeasonalityPatternsModal
      @create="handleCreate"
      @save="handleSave"
    />
    <template #breadcrumbs>
      <SlBreadcrumb @click="goToDemand">
        {{ $t('Main.tabNames.main') }}
      </SlBreadcrumb>
      <SlBreadcrumb
        :index="1"
        active
      >
        {{ $t('Web.SeasonPatterns.Breadcrumb') }}
      </SlBreadcrumb>
    </template>
    <ContentBlock>
      <div class="subpage__actions">
        <SlSearchInput
          :value="search"
          class="w-280"
          @submit="handleSearch"
        />
        <SlButton
          variant="secondary"
          color="grey"
          @click="showCreateModal"
        >
          <template #prepend>
            <icon
              data="@icons/plus.svg"
              class="fill-off size-16 color-grey-80"
            />
          </template>
          {{ $t('Web.SeasonPatterns.CreateBtn') }}
        </SlButton>
      </div>
      <div class="subpage__table-wrapper">
        <div class="subpage__table">
          <SlTable
            id="patterns"
            :headers="tableHeaders"
            :rows="tableRows"
            unique-key="id"
            header-unique-key="key"
            header-name-key="name"
            header-type-key="type"
            search-by="name"
            :hidden-columns-keys="['id', 'actionFgs']"
            :max-height="1000"
            :row-height="40"
            :sort-by="colClass"
            :sort-order="sortOrder"
            :loading="loading"
            :inner-no-data="!!search"
            :initial-col-sizes="['calc(100% - 40px)', '40px']"
            :col-initial-width="40"
            :col-resize="false"
            list
          >
            <template #header-actionFgs-last />

            <template #name="slotScope">
              <div class="sl-table__cell-text body-1-md">
                {{ slotScope.cellValue.val }}
              </div>
            </template>
            <template #actionFgs-last="slotScope">
              <SeasonalityPatternsActionsDropdown
                :pattern="slotScope"
                :actions="slotScope.cellValue.val"
              />
            </template>

            <template #no-data>
              <SlNoData>
                <template #image>
                  <icon
                    :data="noDataMeta.icon"
                    :class="{
                      'illustration-md': !search,
                      'size-48': search,
                      'fill-off': search
                    }"
                  />
                </template>
                <template #title>
                  {{ noDataMeta.title }}
                </template>
                <template #text>
                  {{ noDataMeta.text }}
                </template>
              </SlNoData>
            </template>
          </SlTable>
        </div>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SeasonalityPatternsModal from '@/components/Modals/SeasonPattern/Index.vue';
import SeasonalityPatternsActionsDropdown from '@/components/Demand/SeasonalityPatterns/SeasonalityPatternsActionsDropdown.vue';
import { tableConfig } from '@/mixins/tableConfig';
import { access } from '@/mixins/access';
import { modal } from '@/mixins/modal';
import { routeNames } from '@/config/router/router.config';
import modalsId from '@/config/shared/modalsId.config';
import { toArray } from '@/helpers/utils/toArray';

export default {
  name: 'SeasonalityPatterns',
  components: {
    SeasonalityPatternsActionsDropdown,
    SeasonalityPatternsModal
  },
  mixins: [tableConfig, access, modal],
  data() {
    return {
      modalsId,
      loading: false
    };
  },
  computed: {
    ...mapState({
      table: state => state.demand.seasonPattern.seasonal_patterns,
      tableConfig: state => state.demand.seasonPattern.tableConfig
    }),
    tableHeaders() {
      return toArray(this.table.headers);
    },
    tableRows() {
      return toArray(this.table.rows);
    },
    totalRowsCount() {
      return this.table.totalRowsCount;
    },
    noDataMeta() {
      if (this.search) {
        return {
          icon: require('@icons/style_no_search_results_double.svg'),
          title: this.$t('Web.NoData.SearchTitle'),
          text: this.$t('Web.NoData.SearchText')
        };
      }

      return {
        icon: require('@icons/illustration/no-table-rows.svg'),
        title: this.$t('Web.SeasonPatterns.NoDataTitle'),
        text: this.$t('Web.SeasonPatterns.NoDataText')
      };
    }
  },
  async beforeMount() {
    await this.fetchSeasonalityPatterns();
    await this.fetchSeasonPatternsModel();
  },
  beforeDestroy() {
    this.search = '';
  },
  methods: {
    ...mapActions({
      fetchSeasonPatterns: 'demand/seasonPattern/fetchSeasonPatterns',
      fetchSeasonPatternsModel: 'demand/seasonPattern/fetchSeasonPatternsModel',
      createPattern: 'demand/seasonPattern/createPattern',
      saveSeasonPattern: 'demand/seasonPattern/saveSeasonPattern',
      updateTableConfig: 'demand/seasonPattern/updateTableConfig'
    }),
    async fetchSeasonalityPatterns() {
      try {
        this.loading = true;

        await this.fetchSeasonPatterns();
      } finally {
        this.loading = false;
      }
    },
    goToDemand() {
      this.$router.push({
        name: routeNames.DEMAND
      });
    },
    handleSearch(query) {
      if (!this.search && !this.totalRowsCount) {
        return;
      }

      this.search = query;

      this.fetchSeasonalityPatterns();
    },
    showCreateModal() {
      this.showModal(modalsId.SEASONALITY_PATTERNS, {
        patternId: null,
        fromChannel: 0
      });
    },
    handleCreate(data) {
      this.createPattern(data);
    },
    handleSave(data) {
      this.saveSeasonPattern(data);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/subpage";

.content-block {
  .subpage__actions {
    justify-content: space-between;
  }

  .subpage__table {
    height: 100%;
  }

  .subpage__table-wrapper {
    position: relative;

    height: calc(100% - 108px);
  }
}

::v-deep {
  .sl-table {
    min-width: 100%;
  }

  .sl-table-wrapper {
    height: 100% !important;
  }
}

</style>
