<template>
  <SlModal
    :id="id"
    :title="title"
    persistent
    @on-enter="handleSubmit"
    @created="onCreated"
    @show="onModalShow"
    @hide="onModalHide"
  >
    <div class="modal-content pattern-form">
      <ValidationObserver
        ref="observer"
        class="pattern-form__row"
      >
        <SlValidate
          v-slot="{ invalid }"
          key="name"
          vid="name"
          :rules="nameRules"
        >
          <SlInput
            v-model="localPattern.name"
            :label="$t('Web.SeasonPatterns.Name')"
            required
            :is-invalid="invalid"
          />
        </SlValidate>
      </ValidationObserver>
      <SlTable
        id="pattern-table"
        :headers="tableHeaders"
        :rows="tableRows"
        unique-key="id"
        header-unique-key="key"
        header-name-key="name"
        header-type-key="type"
        :row-height="32"
        :initial-col-sizes="['50%', '50%']"
        :max-height="1000"
        :col-resize="false"
        :value-parser="parseValue"
      >
        <template #value="slotScope">
          <SlTableInput
            :value="getInputValue(slotScope)"
            :input-regexp="regExp.negativeFloatInput"
            :submit-regexp="regExp.floatSubmit"
            @submit="(val) => handleCellSubmit({ val, slotScope })"
          />
        </template>
      </SlTable>
    </div>
    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleSubmit">
          {{ submitButtonText }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import regExp from '@/helpers/utils/regExp';
import modalsId from '@/config/shared/modalsId.config';
import { initialTableGenerator } from '@/config/demand/seasonalityPatterns.config';

export default {
  name: 'SeasonalityPatternsModal',
  mixins: [modal],
  data() {
    return {
      regExp,
      id: modalsId.SEASONALITY_PATTERNS,
      patternId: '',
      fromChannel: '',
      localPattern: {},
      localPatternName: ''
    };
  },
  computed: {
    ...mapGetters({
      patternNames: 'demand/seasonPattern/patternNames'
    }),
    tableRows() {
      return this.localPattern.rows || [];
    },
    tableHeaders() {
      return this.localPattern.headers || [];
    },
    isEditMode() {
      return this.patternId !== null;
    },
    submitButtonText() {
      return this.isEditMode ? this.$t('Web.Modals.BtnSave') : this.$t('Web.Modals.BtnCreate');
    },
    title() {
      return this.isEditMode
        ? this.$t('Web.SeasonPatterns.EditTitle', { 1: this.localPatternName })
        : this.$t('Web.SeasonPatterns.CreateBtn');
    },
    localPatternNames() {
      return this.isEditMode
        ? this.patternNames?.filter(name => name !== this.localPatternName)
        : this.patternNames;
    },
    nameRules() {
      return {
        required: true,
        max: 64,
        'sl_not_includes': {
          values: this.localPatternNames,
          message: this.$t('Error.ErrorDuplicate')
        }
      };
    }
  },
  methods: {
    ...mapActions({
      getSeasonPattern: 'demand/seasonPattern/getCurrentPattern'
    }),
    async onModalShow() {
      if (this.patternId !== null) {
        const pattern = await this.getSeasonPattern(this.patternId);

        this.localPattern = { ...pattern };
        this.localPatternName = pattern.name;
      } else {
        this.localPattern = initialTableGenerator();
      }
    },
    onModalHide() {
      this.patternId = '';
      this.fromChannel = '';
      this.localPattern = {};
      this.localPatternName = '';
    },
    async handleSubmit() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      this.$emit(this.isEditMode ? 'save' : 'create', {
        id: this.patternId,
        data: this.localPattern,
        fromChannel: this.fromChannel
      });

      this.hideModal(this.id);
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    parseValue(cellValue) {
      if (!cellValue) {
        return '';
      }

      if (typeof cellValue !== 'object') {
        return cellValue;
      }

      return cellValue.val || '';
    },
    getInputValue(scope) {
      return scope.cellValue?.val || 0;
    },
    handleCellSubmit({ val, slotScope }) {
      this.localPattern.rows[slotScope.rowId].value.val = val || 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/season-pattern/index.scss";
</style>
